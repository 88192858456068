import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';
import {
  faTv,
  faProjectDiagram,
  faSitemap,
  faFileInvoiceDollar,
  faChartArea,
  faUsersCog,
  faFileMedical,
  faPhotoVideo,
  faUserTie,
  faMapMarked,
  faAd,
  faHouseUser,
  faLayerGroup,
  faCubes,
  faBalanceScaleLeft,
  faBriefcase,
  faBullhorn,
  faFlask,
  faShop,
  faMountainCity,
  faGaugeHigh,
  faPhotoFilm,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";

library.add(
  faTv,
  faProjectDiagram,
  faSitemap,
  faFontAwesome,
  faFileInvoiceDollar,
  faChartArea,
  faUsersCog,
  faFileMedical,
  faPhotoVideo,
  faUserTie,
  faMapMarked,
  faAd,
  faHouseUser,
  faLayerGroup,
  faCubes,
  faBalanceScaleLeft,
  faBriefcase,
  faBullhorn,
  faFlask,
  faShop,
  faMountainCity,
  faGaugeHigh,
  faPhotoFilm,
  faUserAstronaut
);

const NavbarVerticalMenuItem = ({ route, border }) => (
  <>
    {border && <hr className="border-300 my-3" />}
    <Flex
      align="center"
      className={border ? 'my-3' : ''}
    >
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text">{route.name}</span>
      {!!route.badge && (
        <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
          {route.badge.text}
        </Badge>
      )}
    </Flex>
  </>
);

NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired
  }).isRequired,
  border: PropTypes.bool
};

export default React.memo(NavbarVerticalMenuItem);