import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logoBright from '../../assets/img/illustrations/brightLogo.png';
import vericastLogo from '../../assets/img/logos/vericastlogo.png';
import vericastLogoRetail from '../../assets/img/logos/vericastlogoRETAILPORTALa.png'
import viamediaLogoSandBox from '../../assets/img/logos/viaMEDIAlogoSANDBOX.png'
import viamediaLogo from'../../assets/img/logos/viaMEDIAlogo01.png'
import nCountrLogo from'../../assets/img/logos/NCOUNTR_LOGO.png'
import screenverse from '../../assets/img/logos/screenverse.png'
import adminLOGO from '../../assets/img/logos/adminLOGOa.png'
import screenverseDarkmode from '../../assets/img/logos/SVdashlogoBIGdark.png'
import AppContext from '../../context/Context';
import SpectrioLight from '../../assets/img/logos/SPECTRIO_WHITE_BG.png'
import SpectrioDark from '../../assets/img/logos/SPECTRIO_DARK_BG.png'



const Logo = ({ at, width, className, ...rest }) => { 
  const user = JSON.parse(localStorage.getItem("user"));
  const { isDark } = useContext(AppContext);

  const getLogoSrc = () => {
    if ([1].includes(user?.role) && [97].includes(user?.client_id)) {
      return isDark ? screenverseDarkmode : screenverse;
    }
    if ([1].includes(user?.role) && [87].includes(user?.client_id)) {
      return vericastLogoRetail;
    }
    if ([957, 953, 964, 963, 962, 993].includes(user?.user_id)) {
      return vericastLogo;
    }
    if ([1011, 1013, 1014].includes(user?.user_id)) {
      return viamediaLogoSandBox;
    }
    if ([1043].includes(user?.user_id)) {
      return viamediaLogo;
    }
    if ([1126].includes(user?.user_id)) {
      return nCountrLogo;
    }
    if ([26].includes(user?.role) && [1196].includes(user?.user_id)) {
      return isDark ? SpectrioDark : SpectrioLight;
    }
    if ([90].includes(user?.client_id)) {
      return isDark ? SpectrioDark : SpectrioLight;
    }
    return logoBright;
  };

  const getLogoWidth = () => {
    if (([1].includes(user?.role) && [97,90].includes(user?.client_id)) || ([26].includes(user?.role) && [1196].includes(user?.user_id))) {
      return 170;
    }
    if (([1].includes(user?.role) && [87].includes(user?.client_id)) || [1011, 1013, 1014].includes(user?.user_id)) {
      return 220;
    }
    if ([1043].includes(user?.user_id)) {
      return 125;
    }
    return 100;
  };

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img
          src={getLogoSrc()}
          alt="Logo"
          width={getLogoWidth()}
        />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
